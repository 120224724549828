<template>
  <div>
    <b-card title="法律法规合规问卷">
      <b-card-text>
        <span>
          企业应按照相关个人信息保护的法律和法规条款，如实填写本企业的隐私合规遵从情况。</span>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInvalidFeedback,
  BAvatar,
  BFormCheckbox,
  BMedia,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";


export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardCode,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    BMedia,
    StatisticCardVertical,
  },

  data() {
    return {
      queryData: null,
      // select options
      catalogOptions: [
        { value: "", text: "资产类型" },
        "终端PC",
        "服务器",
        "数据库",
        "业务系统",
        "网络设备",
        "安全设备",
        "其他",
      ],
      levelOptions: [
        { value: "", text: "资产分级" },
        "关键资产",
        "重点资产",
        "一般资产",
        "其他",
      ],

      // query
      query_catalog: "",
      query_level: "",
      query_compliance: "",
      query_keyword: "",

      // list table
      sortBy: "index",
      sortDesc: false,
      listTableFields: [
        { key: "id", label: "序号" },
        { key: "name", label: "名称" },
        { key: "type", label: "类型" },
        { key: "level", label: "分级" },
        { key: "ipAddress", label: "IP地址" },
        { key: "port", label: "端口" },
        { key: "note", label: "备注" },
        { key: "actions", label: "操作" },
      ],
      listTablePerPage: 10,
      listTablePageOptions: [10, 15, 30],
      listTableTotalRows: 10,
      listTableCurrentPage: 1,

      // edit
      currentItem: null,
      baseInfoVisible: true,
      adminUser: null,
      showEdit: false,
    };
  },

  computed: {
    listTableItems() {
      return this.queryData ? this.queryData : [];
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    doSearch() {
      // debugger
      this.$http
        .get("/datamapassets").then((res) => {
          this.queryData = res.data.data.list;
          this.listTableTotalRows = res.data.data.total;
        });
    },

    onEditHidden() {
      this.showEdit = false;
    },
  },
};
</script>